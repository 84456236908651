<template>
  <button
    v-if="!favActive"
    @click="setFav"
    :data-fav-id="payload.film.kinopoisk_id"
    :class="{ 'btn': payload.button }"
    class="favorite"
    title="Добавить в избранное"
  >
    <i class="icon-favorite" />
    <span v-if="!payload.button">Добавить в избранное</span>
  </button>
  <button
    v-else
    @click="removeFav"
    :data-fav-id="payload.film.kinopoisk_id"
    :class="{ 'btn': payload.button }"
    class="favorite active"
    title="Удалить из избранного"
  >
    <i class="icon-favorite" />
    <span v-if="!payload.button">Удалить из избранного</span>
  </button>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    payload: {
      type: Object,
      require: true,
      default: () => ({
        film: { title_ru: '', title_en: '', poster: '', kinopoisk_id: null },
        button: false
      })
    }
  },
  computed: {
    ...mapState(['user']),
    favActive() {
      return this.user ? this.user.favs.includes(this.payload.film.kinopoisk_id) : null
    }
  },
  methods: {
    async setFav() {
      if (!this.user) {
        return this.$router.push(`/signin?ref=${encodeURI('/film/' + this.payload.film.kinopoisk_id)}`)
      }
      const { data } = await this.$axios.post(`/api/favs`, this.payload.film)
      if (!data.error) {
        const user = this.user
        user.favs.push(data.kpid)
        this.$store.commit('setUser', user)
        const img = document.createElement('img')
        img.className = 'flyposter'
        img.src = this.filmPoster(this.payload.film.kinopoisk_id)
        const parent = document.querySelector(`[data-video-id="${this.payload.film.kinopoisk_id}"]`)
        if (parent) {
          parent.appendChild(img)
          setTimeout(() => {
            parent.removeChild(img)
          }, 2000)
        }
      }
    },
    async removeFav() {
      if (!this.user) {
        return this.$router.push(`/signin?ref=${encodeURI('/film/' + this.payload.film.kinopoisk_id)}`)
      }
      const { data } = await this.$axios.delete(`/api/favs?id=${this.payload.film.kinopoisk_id}`)
      if (!data.error) {
        const user = this.user
        const favs = user.favs.filter(el => el !== data.kpid)
        user.favs = favs
        this.$store.commit('setUser', user)
        const videoElem = document.querySelector(`.content.favs .videos .item[data-video-id="${this.payload.film.kinopoisk_id}"]`)
        if (videoElem) {
          document.querySelector(`.videos`).removeChild(videoElem)
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.favorite
  width: 100%
  padding: 14px
  display: grid
  grid-template-columns: 24px auto
  align-items: center
  background-color: rgba(255 255 255 .3)
  &:hover
    background-color: rgba(255 255 255 .5)
  &.active i.icon-favorite
    background-image: url('/static_files/icons/favorite-active.svg')
  .icon-favorite
    width: 24px
    height: 24px
    background-image: url('/static_files/icons/favorite.svg')
    background-size: 24px
  &.btn
    grid-template-columns: auto
    grid-gap: 0px
    span
      display: block
  span
    display: none
</style>
